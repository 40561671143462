import React, { useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import HelmetReact from "../elements/HelmetReact";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import GoalArea from "../components/GoalArea";
import AboutOne from "../components/AboutOne";
import ProcessThree from "../components/ProcessThree";
import TestimonialOne from "../components/TestimonialOne";
import CounterOne from "../components/CounterOne";
import WhyChoose from "../components/WhyChoose";
import FooterOne from "../components/FooterOne";
import Newsletter from "../components/Newsletter";

const About = () => {
  let [active, setActive] = useState(true);

  const [dataService, setDataService] = useState([]);
  const [dataBlog, setDataBlog] = useState([]);
  const [dataContact, setDataContact] = useState([]);
  const [about, setAbout] = useState([]);
  const [getServices2, setServices2] = useState([]);

  useEffect(() => {
    const apiServices2 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServices2Yakin/select/";

    fetch(apiServices2)
      .then((response) => response.json())
      .then((data) => {
        setServices2(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 

  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);

  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getBlogDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataBlog(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 



  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServicesYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataService(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getContactDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataContact(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getAboutDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 

  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Helmet */}
      <HelmetReact title={"Hakkımızda"} />

      {/* HeaderFive */}
      <HeaderOne getServices2={getServices2} dataContact={dataContact} dataService={dataService} dataBlog={dataBlog} />

      {/* Breadcrumb */}
      <Breadcrumb title="Hakkımızda" />

      {/* GoalArea */}
      <GoalArea about={about}/>

      {/* AboutOne */}
      <AboutOne />

      {/* ProcessThree */}
      <ProcessThree />

      {/* TestimonialOne  <div className="space">
        <TestimonialOne />
      </div> */}
     

      {/* CounterOne */}
      <CounterOne />

      {/* WhyChoose   <div className="space">
        <WhyChoose />
      </div> */}
    

      {/* Newsletter */}
      <Newsletter />

      {/* FooterOne */}
      <FooterOne />









    </>
  );
};

export default About;
