import React from 'react'
import { Link } from 'react-router-dom'

const ServiceView = () => {
    return (
        <div className="service-area-1 space overflow-hidden">
            <div className="container">
                <div className="row gx-30 gy-30 justify-content-center">
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-1.png" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">Sağlıklı Beslenme Paketi</Link>
                                </h4>
                                <p className="service-card_text">
                                Deneme ..

                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Daha Fazlası <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-1.png" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">Arınma Paketleri</Link>
                                </h4>
                                <p className="service-card_text">
                                Deneme ..

                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Daha Fazlası <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-1.png" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">Atıştırmalıklar</Link>
                                </h4>
                                <p className="service-card_text">
                                Deneme ..

                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Daha Fazlası <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="service-card">
                            <div className="service-card_img">
                                <img src="assets/img/service/service-1-1.png" alt="img" />
                            </div>
                            <div className="service-card_content">
                                <h4 className="service-card_title">
                                    <Link to="/service-details">Smoothieler</Link>
                                </h4>
                                <p className="service-card_text">
                                   Deneme ..
                                </p>
                                <Link to="/service-details" className="link-btn">
                                    Daha Fazlası <i className="fas fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>

    )
}

export default ServiceView