import React from 'react'
import { Link } from 'react-router-dom'

const TeamAll = (props) => {
    return (
        <div className="team-area-1 space">
            <div className="container">
                <div className="row gy-30 gx-30">
                {props.getOurService.map((item, index) => (

                    <div className="col-lg-4 col-md-6">
                        <div className="team-card mb-0">
                            <div className="team-card_wrapp">
                                <div className="team-card_img">
                                    <img src={'https://megasubdomain.stechomeyazilim.info/' + item.OurServicePoints_Img} alt="img" />
                                </div>
                                <div className="team-social">
                                    <div className="plus-btn">
                                        <img src="assets/img/icon/share.svg" alt="Yakın" />
                                    </div>
                                    <div className="global-social">
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                        <Link to="#" tabIndex={0}>
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#">
                                            <img src="assets/img/icon/share2.svg" alt="Yakın" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="team-card_content">
                                <h4 className="team-card_title">
                                    <Link  to={ "/team-details/" + (item.ID)} state={{ _item: item}}>{item.OurServicePoints_Title}</Link>
                                </h4>
                                <span className="team-card_desig">{item.OurServicePoints_City}</span>
                            </div>
                        </div>
                    </div>
                ))}
                
                </div>
            </div>
        </div>

    )
}

export default TeamAll