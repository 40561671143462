import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HeaderOne = (props) => {
  const [active, setActive] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [scroll, setScroll] = useState(false);


  useEffect(() => {
    let offCanvasNav = document.getElementById("offcanvas-navigation");
    let offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='mean-expand-class'>+</span>"
      );
    }

    let menuExpand = offCanvasNav.querySelectorAll(".mean-expand-class");
    let numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
    window.onscroll = () => {
      if (window.pageYOffset < 250) {
        setScroll(false);
      } else if (window.pageYOffset > 250) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
  }, []);

  const mobileMenu = () => {
    setActive(!active);
  };


  const sidebarControl = (active) => {
    setSidebar(active);
  };

  return (
    <>
      <div
        className={`sidemenu-wrapper sidemenu-info ${sidebar ? "show" : ""} `}
      >
        <div className="sidemenu-content">
          <button
            className="closeButton sideMenuCls"
            onClick={() => sidebarControl(false)}
          >
            <i className="fas fa-times" />
          </button>
          <div className="widget  ">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src="/assets/img/logo.png" alt="Laun" />
                </Link>
              </div>
              <p className="about-text">
               Deneme Yazı
              </p>
            {  props.dataContact.length > 0 ? 
              <div className="social-links">
                 <a rel="noreferrer" target="_blank" href={props.dataContact[0].Youtube_Address}>
                  <i className="fab fa-youtube" />
                </a>
               
                <a rel="noreferrer" target="_blank" href={props.dataContact[0].Linkedin_Address}>
                  <i className="fab fa-linkedin-in" />
                </a>
                <a rel="noreferrer" target="_blank" href={props.dataContact[0].Phone}>
                  <i className="fab fa-whatsapp" />
                </a>
              </div> : null}
            </div>
          </div>
          {props.dataContact.length > 0 ? 
          <div className="side-info mb-30">
            <div className="contact-list mb-20">
              <h4>Adres</h4>
              <p>{props.dataContact[0].Adress}</p>
            </div>
            <div className="contact-list mb-20">
              <h4>Telefon</h4>
              <p className="mb-0">{props.dataContact[0].Phone}</p>
            </div>
            <div className="contact-list mb-20">
              <h4>Mail Adresi</h4>
              <p className="mb-0">{props.dataContact[0].Mail}</p>
            </div>
          </div> : null}
          {/** 
          <ul className="side-instagram list-wrap">
            <li>
              <Link to="#">
                <img src="assets/img/gallery/1.jpg" alt="Bizmaster" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="assets/img/gallery/2.jpg" alt="Bizmaster" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="assets/img/gallery/3.jpg" alt="Bizmaster" />
              </Link>
            </li>
            <li>
              <Link to="#">
                <img src="assets/img/gallery/4.jpg" alt="Bizmaster" />
              </Link>
            </li>
          </ul>*/}
        </div>
      </div>
     {/**  <div className={`popup-search-box ${search === true ? "show" : ""} `}>
        <button className="searchClose" onClick={() => searchControl(false)}>
          <i className="fas fa-times" />
        </button>
        <form>
          <input type="text" placeholder="What are you looking for?" />
          <button type="submit">
            <i className="fas fa-search" />
          </button>
        </form>
      </div>*/}
      {/*==============================
    Mobile Menu
    ============================== */}
      <div className={`mobile-menu-wrapper ${active ? "body-visible" : ""}`}>
        <div className="mobile-menu-area">
          <div className="mobile-logo">
            <Link to="/">
              <img src="/assets/img/logo.png" alt="Logo" />
            </Link>
            <button className="menu-toggle" onClick={mobileMenu}>
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="mobile-menu">
            <ul id="offcanvas-navigation">
              <li className="menu-item-has-children submenu-item-has-children">
                <Link to="/">Anasayfa</Link>
             {/** */}   <ul className="sub-menu submenu-class">
                
                </ul>
              </li>

              <li>
                <Link to="/about">Hakkımızda</Link>
              </li>
              {/* <li className="menu-item-has-children">
                <Link to="#">Pages</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pricing">Pricing Page</Link>
                  </li>
                  <li>
                    <Link to="/team">Team</Link>
                  </li>
                  <li>
                    <Link to="/team-details">Team Details</Link>
                  </li>
                </ul>
              </li> */}
            <li className="menu-item-has-children">
                <Link to="#">Markalar</Link>
                <ul className="sub-menu">
                {props.getServices2.map((item, index) => (
                   <li>
                          <Link  to={ "/project-details/" + (item.ID)} state={{ _item: item}}>{item.Services2_Title}</Link>
                  </li>))}
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="#">Hizmetler</Link>
                <ul className="sub-menu">
                
                {props.dataService.map((item, index) => (
                   <li>
                          <Link  to={ "/service-details/" + (item.ID)} state={{ _item: item}}>{item.Services_Title}</Link>
                  </li>))}
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="#">Blog</Link>
               {/** */} <ul className="sub-menu">
               {props.dataBlog.map((item, index) => (
                  <li>
                    <Link to={ "/blog-details/" + (item.ID)} state={{ _item: item}}>{item.Title}</Link>
                  </li>))}
                
                </ul>
              </li>
              <li>
                <Link to="/contact">İletişim</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Header Area */}

      <header className="nav-header header-layout1">
        <div className={`sticky-wrapper ${scroll && "sticky"}`}>
          {/* Main Menu Area */}
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="header-logo">
                  <Link to="/">
                    <img src="/assets/img/logo.png" alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-auto ms-xl-auto">
                <nav className="main-menu d-none d-lg-inline-block">
                  <ul>
                    <li className="">
                      <Link to="/">Anasayfa</Link>
                    {/**   <ul className="sub-menu">
                        <li>
                          <Link to="/">Home 01</Link>
                        </li>
                        <li>
                          <Link to="/home-2">Home 02</Link>
                        </li>
                        <li>
                          <Link to="/home-3">Home 03</Link>
                        </li>
                        <li>
                          <Link to="/home-4">Home 04</Link>
                        </li>
                        <li>
                          <Link to="/home-5">Home 05</Link>
                        </li>
                      </ul>*/}
                    </li>

                    <li>
                      <Link to="/about">Hakkımızda</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="#">Hizmetler</Link>
                      <ul className="sub-menu">
                        
                      {props.dataService.map((item, index) => (
                   <li>
                          <Link  to={ "/service-details/" + (item.ID)} state={{ _item: item}}>{item.Services_Title}</Link>
                  </li>))}
                      
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="#">Markalar</Link>
                      <ul className="sub-menu">
                      
                {props.getServices2.map((item, index) => (
                   <li>
                          <Link  to={ "/project-details/" + (item.ID)} state={{ _item: item}}>{item.Services2_Title}</Link>
                  </li>))}
                      </ul>
                    </li> 
                    <li className="menu-item-has-children">
                      <Link to="#">Blog</Link>
                      <ul className="sub-menu">
                      {props.dataBlog.map((item, index) => (
                        <li>
                          <Link to={ "/blog-details/" + (item.ID)} state={{ _item: item}}>{item.Title}</Link>
                        </li>))}
                        
                      </ul>
                    </li>

                    <li>
                      <Link to="/team">Hizmet Noktalarımız</Link>
                    </li>

                  {/**   <li className="menu-item-has-children">
                      <Link to="#">Pages</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/pricing">Pricing Page</Link>
                        </li>
                        <li>
                          <Link to="/team">Team</Link>
                        </li>
                        <li>
                          <Link to="/team-details">Team Details</Link>
                        </li>
                        <li>
                          <Link to="/contact">İletişim</Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to="/contact">İletişim</Link>
                    </li>
                  </ul>
                </nav>
                <div className="navbar-right d-inline-flex d-lg-none">
                  <button
                    type="button"
                    className="menu-toggle icon-btn"
                    onClick={mobileMenu}
                  >
                    <i className="fas fa-bars" />
                  </button>
                </div>
              </div>
              <div className="col-auto ms-xxl-4 d-xl-block d-none">
                <div className="header-wrapper">
                  <div className="header-button">
                  {/***  <button
                      type="button"
                      onClick={() => searchControl(true)}
                      className="simple-icon searchBoxToggler"
                    >
                      <i className="fas fa-search" />
                    </button>*/} 
                    <button
                      onClick={() => sidebarControl(true)}

                      className="simple-icon sideMenuToggler d-none d-lg-block"
                    >
                      <img src="/assets/img/icon/bars.svg" alt="Yakın Mutfak" />
                    </button>
                  </div>
                  {  props.dataContact.length > 0 ? 
              <div className="social-links">
            
                <a rel="noreferrer" target="_blank" href = {props.dataContact[0].InstagramAddress}>
                  <i className="fab fa-instagram" />
                </a>
                <a rel="noreferrer" target="_blank" href ={props.dataContact[0].Phone}>
                  <i className="fab fa-whatsapp" />
                </a>
              </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderOne;
