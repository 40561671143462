import React from 'react'
import { Base64 } from 'js-base64';

const GoalArea = (props) => {
    return (
        <div className="goal-area space">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="title-area">
                            <span className="sub-title">
                                <img src="assets/img/icon/title_left.svg" alt="shape" />
                                Yakın Mutfak
                            </span>
                            <h2 className="sec-title style2">Başarı için İş Ortaklığı</h2>
                        </div>
                    </div>
                   {/** <div className="col-lg-6">
                        <div className="goal-title-area">
                            <p className="">
                                Deneme..
                            </p>
                        </div>
                    </div>*/} 
                </div>
                <div className="row">
                    <div className="goal-tabs-wrapper">
                        <div
                            className="nav nav-tabs goal-tabs-tabs"
                            id="nav-tab"
                            role="tablist"
                        >
                            <button
                                className="nav-link"
                                id="nav-step1-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step1"
                                type="button"
                            >
                                Vizyon
                            </button>
                            <button
                                className="nav-link"
                                id="nav-step2-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step2"
                                type="button"
                            >
                                Misyon
                            </button>
                            <button
                                className="nav-link active"
                                id="nav-step3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step3"
                                type="button"
                            >
                                Deneyim
                            </button>
                            <button
                                className="nav-link"
                                id="nav-step4-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-step1"
                                type="button"
                            >
                                Hakkımızda
                            </button>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade" id="nav-step1" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="Bizmaster" />
                                        <div className="goal-content">

                                        {props.about.length > 0 ?
                                        <div dangerouslySetInnerHTML={{ __html: (Base64.decode(props.about[0].Vision)) }} />
                                        : null}

{/** 
                                            <h4 className="box-title">
                                               Deneme ..
                                            </h4>
                                            <p>Deneme ..</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                </ul>
                                            </div>*/}
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="Bizmaster" />
                                        <div className="goal-content">
                                        {props.about.length > 0 ?
                                        <div dangerouslySetInnerHTML={{ __html: (Base64.decode(props.about[0].Mission)) }} />
                                        : null}


                                          {/**   <h4 className="box-title">
                                            Deneme ..
                                            </h4>
                                            <p>Deneme ..</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                </ul>
                                            </div>*/}
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            <div
                                className="tab-pane fade active show"
                                id="nav-step3"
                                role="tabpanel"
                            >
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="Bizmaster" />
                                        <div className="goal-content">

                                        {props.about.length > 0 ?
                                        <div dangerouslySetInnerHTML={{ __html: (Base64.decode(props.about[0].Experience)) }} />
                                        : null}

                                        {/**
                                            <h4 className="box-title">
                                            Deneme ..
                                            </h4>
                                            <p>Deneme ..</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-step4" role="tabpanel">
                                <div className="goal-list_wrapper">
                                    <div className="goal-content_wrapp">
                                        <img src="assets/img/icon/angles-left.svg" alt="Bizmaster" />
                                        <div className="goal-content">

                                        {props.about.length > 0 ?
                                        <div dangerouslySetInnerHTML={{ __html: (Base64.decode(props.about[0].AboutDesc)) }} />
                                        : null}
                                        {/** 
                                            <h4 className="box-title">
                                            Deneme ..
                                            </h4>
                                            <p>SDeneme ..</p>
                                            <div className="checklist">
                                                <ul>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check" />
                                                        Deneme ..
                                                    </li>
                                                </ul>
                                            </div>*/}
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GoalArea