import React from "react";
import { Helmet } from "react-helmet";
const HelmetReact = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title} || Yakın Mutfak - Yemek Hizmetleri
      </title>
      <meta
        name="description"
        content="Yakın Mutfak - Yemek Hizmetleri"
      />
    </Helmet>
  );
};

export default HelmetReact;
