import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FooterOne = () => {

  const [dataContact, setDataContact] = useState([]);

  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getContactDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataContact(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır

  return (
    dataContact.length > 0 ?
    <footer
      className="footer-wrapper footer-layout1"
      style={{ backgroundImage: "url(assets/img/bg/footer-bg1-1.png)" }}
    >
      <div className="container">
        <div className="widget-area">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-5">
              <div className="widget footer-widget widget-about">
                <div className="about-logo">
                  <Link to="/">
                    <img src="/assets/img/logoWhite.png" alt="Bizmaster" />
                  </Link>
                </div>
                <p className="footer-text mb-30">
                  Yakın Mutfak Kısa Bilgilendirme
                </p>
                <div className="social-btn style3">
                  <a target="_blank" href={'https:instagram.com/' + dataContact[0].InstagramAddress} tabIndex={-1}>
                    <i className="fab fa-instagram" />
                  </a>
                {/**<Link to="#" tabIndex={-1}>
                    <i className="fab fa-linkedin-in" />
                  </Link>
                  <Link to="#" tabIndex={-1}>
                    <i className="fab fa-twitter" />
                  </Link>
                  <Link to="#" tabIndex={-1}>
                    <i className="fab fa-facebook-f" />
                  </Link> */}  
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Useful Links</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="/service">Software Corner</Link>
                    </li>
                    <li>
                      <Link to="/service">Application Center</Link>
                    </li>
                    <li>
                      <Link to="/service">Research Section</Link>
                    </li>
                    <li>
                      <Link to="/service">Developing Corner</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>*/}
            <div className="col-md-6 col-xl-3">
              <div className="widget footer-widget me-xl-3">
                <h3 className="widget_title">İletişim</h3>
                <div className="widget-contact2">
                  <div className="widget-contact-grid">
                    <i className="fas fa-phone-alt" />
                    <div className="contact-grid-details">
                      <h6>
                        <Link to="#">{dataContact[0].Phone}</Link>
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <i className="fas fa-envelope" />
                    <div className="contact-grid-details">
                      <h6>
                        <Link to="#">
                        {dataContact[0].Mail}
                        </Link>
                        <p />
                      </h6>
                    </div>
                  </div>
                  <div className="widget-contact-grid">
                    <i className="fas fa-map-marker-alt" />
                    <div className="contact-grid-details">
                      <h6>
                      {dataContact[0].Adress}
                        <p />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright-wrap">
          <div className="row gy-3 justify-content-lg-between justify-content-center">
            <div className="col-auto align-self-center">
              <p className="copyright-text text-center">
                © <Link to="#">Stechome </Link> 2024 | Bütün Hakları Saklıdır
              </p>
            </div>
            <div className="col-auto">
              <div className="footer-links">
                <Link to="/contact">Gizlilik</Link>
                <Link to="/contact">Şartlar</Link>
                <Link to="/contact">Site Haritası</Link>
                <Link to="/contact">Yardım</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer> : null
  );
};

export default FooterOne;
