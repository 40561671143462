import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const BlogOne = () => {

  
  const [dataBlog, setDataBlog] = useState([]);

  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getBlogDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataBlog(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır

  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="blog-area-1 space">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="title-area text-center">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Son Blog Yazıları
                </span>
               {/** <h2 className="sec-title">
                  Strategic Solutions for Business Growth
                </h2>*/} 
              </div>
            </div>
          </div>
          <div className="row global-carousel blog-slider slider-shadow">
            <Slider {...settings}>
            {dataBlog.map((item, index) => (
              <div className="blog-card p-3">
                <div className="blog-img">
                  <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Blog_Image} alt="Bizmaster" />
                </div>
                <div className="blog-content">
                  <div className="blog-meta">
                    <Link to="/blog">
                      <i className="fas fa-user" />
                      Yakın Mutfak Tarafından
                    </Link>
                    
                  </div>
                  <h3 className="blog-title">
                    <Link to={ "/blog-details/" + (item.ID)} state={{ _item: item}}>
                  {item.Title}
                    </Link>
                  </h3>
                </div>
              </div>))}

        
            </Slider>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="footer-top-1 bg-theme">
          <div className="footer-top-content">
            <h3 className="footer-top-title text-white">Abonelik</h3>
            <p className="footer-top-text">
             Yakın Mutfak ile ilgili gelişmelerden haberdar olmak istiyor musun?
            </p>
          </div>
          <form className="newsletter-form">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Mail Adresiniz"
                required=""
              />
              <button type="submit" className="global-btn">
                Abone Ol
                <span className="icon">
                  <i className="fa-sharp fas fa-paper-plane ms-2" />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BlogOne;
