import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

// Swiper modüllerini kullanıma alıyoruz
SwiperCore.use([Autoplay, Pagination, Navigation]);

const HeroOne = (props) => {
  const [data3, setData3] = useState([]);

  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getSliderYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setData3(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır

  return (
    <div
      className="hero-wrapper bg-smoke hero-1"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/hero_bg_1_1.png)" }}
    >
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop={true} // Slider'ın sonsuz döngüde çalışması
        autoplay={{
          delay: 3000, // Otomatik kayma süresi (ms cinsinden). 3000ms = 3 saniye
          disableOnInteraction: false, // Kullanıcı etkileşimde bulunduktan sonra otomatik kayma devam etsin
        }}
      >
        {data3.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="container">
              <div className="row align-items-end">
                <div className="col-xl-4">
                  <div className="hero-style1">
                    <span className="sub-title">
                      <img src="assets/img/icon/title_left.svg" alt="shape" />
                      Yakın Mutfak
                    </span>
                    <h1 className="hero-title">{item.SliderTitle}</h1>
                    <p className="hero-text">{item.Slider_Desc}</p>
                    <div className="btn-group">
                      <Link to="/project" className="global-btn">
                        Servislerimiz{" "}
                        <img src="assets/img/icon/right-icon.svg" alt="Bizmaster" />
                      </Link>
                      <Link to="/service" className="global-btn style-border">
                        Hizmetlerimiz
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="hero-image-wrapp">
                    <div className="hero-thumb text-center">
                      <img
                        src={
                          "https://megasubdomain.stechomeyazilim.info/" +
                          item.SliderImage
                        }
                        alt="img"
                      />
                    </div>
                  {/**  <div className="hero-shape1" />
                    <div className="hero-shape2" />
                    <div className="hero-shape3" />
                    <div className="hero-shape4" />
                    <div className="hero-shape5 spin" />*/} 
                  </div>
                </div>
              </div>
              
              <div className="hero-item-content">
              <div className="hero-card_wrapper">
              {props.getServices2.map((item2, index) => (
            <Link to={ "/project-details/" + (item2.ID)} state={{ _item: item2}} className="hero-card">
              <div className="hero-card_icon">
                <img src={'https://megasubdomain.stechomeyazilim.info/' + item2.Services2_SliderImg} alt="img" />
              </div>
             {/*<div className="hero-card_content">
              <Link style={{color:'white'}} to={ "/project-details/" + (item2.ID)} state={{ _item: item2}}>{item2.Services2_Title}</Link>

              <p className="hero-card_text">Deneme</p>
              </div>*/} 
             
            </Link>))}
          
        
            </div>
            </div>

            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroOne;
