import React from 'react'
import { Link } from 'react-router-dom'

const TeamDetailsInner = (props) => {
    return (
        <div className="team-details-area space">
            <div className="container">
                <div className="single-team-details">
                    <div className="team-about-card">
                        <div className="row g-lg-0">
                            <div className="col-lg-6">
                                <div className="team-about-card_img">
                                {props._item != null ? <img
                                        className="w-100"
                                        src={'https://megasubdomain.stechomeyazilim.info/' + props._item.OurServicePoints_Img}
                                        alt="team "
                                    /> : null}
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="team-about-card_box">
                                 {/**    <h3 className="team-about-card_title">İstatistikler</h3>
                                    <div className="skill-feature position-relative">
                                        <h3 className="skill-feature_title">Memnuniyet</h3>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: "80%" }}></div>
                                            <div className="progress-value">
                                                <span className="counter-number">80</span>%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill-feature position-relative">
                                        <h3 className="skill-feature_title">Servis</h3>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: "90%" }}></div>
                                            <div className="progress-value">
                                                <span className="counter-number">90</span>%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill-feature position-relative">
                                        <h3 className="skill-feature_title">Hız</h3>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: "70%" }}></div>
                                            <div className="progress-value">
                                                <span className="counter-number">70</span>%
                                            </div>
                                        </div>
                                    </div>*/}
                                    <p>
                                    {props._item.OurServicePoints_Desc}
                                    </p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                   { props.dataContact.length > 0 ?
                    <div className="row gy-40">
                        <div className="col-lg-4">
                            <div className="team-details-wrap">
                                <h4 className="team-details-wrap-title">Sorumlu Kişi</h4>
                                <h6 className="team-details-wrap-desig">Marketing</h6>
                                <p className="team-details-wrap-text mb-30">
                                   İletişim
                                </p>
                                <div className="team-details-wrap_info">
                                    <span className="icon">
                                        <i className="fas fa-map-marker-alt" />
                                    </span>
                                    <p>
                                    <br />
                                    {props.dataContact[0].Adress}
                                    </p>
                                </div>
                                <div className="team-details-wrap_info">
                                    <span className="icon">
                                        <i className="fas fa-phone-alt" />
                                    </span>
                                    <p>
                                        <Link to="#">{props.dataContact[0].Phone}</Link> <br />
                                    </p>
                                </div>
                                <div className="team-details-wrap_info">
                                    <span className="icon">
                                        <i className="fas fa-envelope" />
                                    </span>
                                    <p>
                                        <Link to="#"> {props.dataContact[0].Mail}</Link> <br />
                                      
                                    </p>
                                </div>
                                <div className="social-btn style4 mt-35">
                                    <Link to="#">
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-twitter" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-instagram" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-pinterest" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="team-contact-wrap">
                                <h3 className="mt-n1 mb-30 page-title">İletişim Formu</h3>
                                <form
                                    className="team-form ajax-contact"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control style-border"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Adınız"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control style-border"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Mail Adresiniz"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        <textarea
                                            placeholder="Mesajınızı Giriniz.."
                                            id="contactForm"
                                            className="form-control style-border"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="form-btn col-12">
                                        <button className="global-btn">
                                            Gönder<i className="fas fa-arrow-right ms-2" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>

    )
}

export default TeamDetailsInner