import { Base64 } from 'js-base64'
import React from 'react'
import { Link } from 'react-router-dom'

const ServiceInnerDetails = (props) => {
    return (
        <section className="space">
            <div className="container">
                <div className="row gx-30">
                    <div className="col-xxl-12 col-lg-7">
                        <div className="page-thumb">
                            <img src={'https://megasubdomain.stechomeyazilim.info/' + props._item.Services_DetailImg} alt="img" />
                        </div>
                      {/**   <ul className="service-details-list">
                            <li className="single-service-list">
                                <span className="title">Kategori </span>
                                <h6 className="content">Sağlık</h6>
                            </li>
                            <li className="single-service-list">
                                <span className="title">Yazar </span>
                                <h6 className="content">Stechome</h6>
                            </li>
                            <li className="single-service-list">
                                <span className="title">Oluşturma Tarihi </span>
                                <h6 className="content">Ocak-2024 </h6>
                            </li>
                        </ul>*/}
                        <h3 className="page-title mt-40 mb-20">{props._item.Services_Title}</h3>
                        <p>
                        {props._item != null && props._item.Services_DescHtml != null?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(props._item.Services_DescHtml)) }} />
                                        : null}
                        </p>
                      
                       
                    
                
                    </div>
                    {/** 
                    <div className="col-xxl-4 col-lg-5">
                        <aside className="sidebar-area">
                            <div className="widget widget_categories widget_service-categories">
                                <ul>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                    <li>
                                        <Link to="/service">Deneme ..</Link>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>*/}
                </div>
            </div>
        </section>

    )
}

export default ServiceInnerDetails