import React from 'react'
import { Link } from 'react-router-dom'

const PortfolioAll = (props) => {
    return (
        <div className="portfolio-area-1 space">
            <div className="container">
                <div className="row gx-30 gy-60">
                {props.getServices2.map((item, index) => (
                    <div className="col-lg-6">
                        <div className="portfolio-card">
                            <div className="portfolio-card-thumb">
                                <img  src={'https://megasubdomain.stechomeyazilim.info/' + item.Services2_Image} alt="img" />
                            </div>
                            <div className="portfolio-card-details">
                                <div className="media-left">
                                    <span className="portfolio-card-details_subtitle">
                                    {item.Services2_ShortDesc}
                                    </span>
                                    <h4 className="portfolio-card-details_title">
                                        <Link  to={ "/project-details/" + (item.ID)} state={{ _item: item}}>
                                            {item.Services2_Title}
                                        </Link>
                                    </h4>
                                </div>
                                <Link  to={ "/project-details/" + (item.ID)} state={{ _item: item}} className="icon-btn">
                                    <img src="assets/img/icon/arrow-up-right.svg" alt="Bizmaster" />
                                </Link>
                            </div>
                        </div>
                    </div>))}
                  
                </div>
            </div>
        </div>

    )
}

export default PortfolioAll