import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
const MarqueeOne = (props) => {
  return (
    <div className="marquee-area bg-smoke">
      <div className="container-fluid p-0 overflow-hidden">
        <div className="slider__marquee">
        <div className="title-area text-center">
              <span className="sub-title">
                <img src="assets/img/icon/title_left.svg" alt="shape" />
                MARKALARIMIZ
              </span>
           
            </div>
          <div className="marquee_mode">
            <Marquee speed={100}>
            {props.getServices2.map((item2, index) => (
               <div key={index} className="item">
               
               <img src={'https://megasubdomain.stechomeyazilim.info/' + item2.Services2_Image} alt="img" />
               <span className="text-stroke">{item2.Services2_Title}</span>
            
           </div>
            ))}
             
          
             
              
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarqueeOne;
