import React, { useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import HelmetReact from "../elements/HelmetReact";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import Newsletter from "../components/Newsletter";
import TeamAll from "../components/TeamAll";



const Team = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);

  const [dataService, setDataService] = useState([]);
  const [dataBlog, setDataBlog] = useState([]);
  const [dataContact, setDataContact] = useState([]);
  const [getServices2, setServices2] = useState([]);
  const [getOurService, setOurService] = useState([]);

  useEffect(() => {
    const apiServices2 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServices2Yakin/select/";

    fetch(apiServices2)
      .then((response) => response.json())
      .then((data) => {
        setServices2(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getBlogDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataBlog(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 



  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServicesYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataService(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getContactDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataContact(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getOurServicePointsYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setOurService(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 

  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Helmet */}
      <HelmetReact title={"Team"} />

      {/* HeaderFive */}
      <HeaderOne getServices2={getServices2} dataContact={dataContact} dataService={dataService} dataBlog={dataBlog}/>

      {/* Breadcrumb */}
      <Breadcrumb title="Hizmet Noktalarımız" />

      {/* TeamAll */}
      <TeamAll getOurService={getOurService}/>


      {/* Newsletter */}
      <Newsletter />

      {/* FooterOne */}
      <FooterOne />

    </>
  );
};

export default Team;
