import React, { useEffect, useState } from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

const FaqOne = () => {

  const [counter, setCounter] = useState([]);
  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getCounterYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setCounter(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 

  return (
    <section className="">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-xl-6">
            <div className="title-area me-xl-5 mb-20">
              <span className="sub-title">
                <img src="assets/img/icon/title_left.svg" alt="shape" />
                SSS
              </span>
              <h2 className="sec-title style2">Yenilik Sizi İleriye Taşır</h2>
            </div>
            <div className="accordion-area accordion" id="faqAccordion">
              <div className="accordion-card active">
                <div className="accordion-header" id="collapse-item-1">
                  <button
                    className="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-1"
                    aria-expanded="true"
                    aria-controls="collapse-1"
                  >
                    Yakın Mutfak Ne Yapar?
                  </button>
                </div>
                <div
                  id="collapse-1"
                  className="accordion-collapse collapse show"
                  aria-labelledby="collapse-item-1"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                     Deneme
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-card ">
                <div className="accordion-header" id="collapse-item-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-2"
                    aria-expanded="false"
                    aria-controls="collapse-2"
                  >
                   Size Nasıl Ulaşabilirim?
                  </button>
                </div>
                <div
                  id="collapse-2"
                  className="accordion-collapse collapse "
                  aria-labelledby="collapse-item-2"
                  data-bs-parent="#faqAccordion"
                >
                  <div className="accordion-body">
                    <p className="faq-text">
                      Deneme
                    </p>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          <div className="col-xl-6">
            <div className="ms-xl-5">
            {/**  <p className="counter-text">
               Deneme Yazı..
              </p>*/} 
              <div className="counter-box_wrapper">
              {counter.map((item, index) => (
    <div className="counter-box">
    <div className="counter-box_icon">
      <img
        src="assets/img/icon/counter-icon_1-1.svg"
        alt="icon"
      />
    </div>

    <div className="media-body">
      <h2 className="counter-box_number">
        <TrackVisibility once>
          {({ isVisible }) =>
            isVisible && (
              <span className="counter-number">
                <CountUp delay={0} start={0} end={item.CounterNumber} />
               
              </span>
            )
          }
        </TrackVisibility>
      </h2>
      <p className="counter-box_text">{item.Counter_Title}</p>
    </div>
  </div>
              ))}
            
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqOne;
