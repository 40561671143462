import React, { useEffect, useState } from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const CounterOne = () => {

  const [counter, setCounter] = useState([]);
  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getCounterYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setCounter(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 

  return (
    <div className="container space">
      <div className="counter-area-2 bg-smoke">
        <div className="row gy-40 justify-content-between">
        {counter.map((item, index) => (
           <div className="col-sm-6 col-lg-auto">
            <div className="counter-card style2">
              <div className="media-body">
                <h2 className="counter-card_number">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter-number">
                          <CountUp delay={0} start={0} end={item.CounterNumber} />
                         
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className="counter-card_text">{item.Counter_Title}</p>
              </div>
            </div>
          </div>))}
         
        </div>
      </div>
    </div>
  );
};

export default CounterOne;
