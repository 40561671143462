import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AboutOne = () => {

  const [about, setAbout] = useState([]);
  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getAboutDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır



  return (
    <div className="about-area-1 position-relative space-top">
      <div className="about1-shape-img1">
        <img
          className="about1-shape-img-1"
          src="assets/img/normal/about_shape1-1.jpg"
          alt="img"
        />
      </div>
      <div className="about1-shape-img2">
        <img
          className="about1-shape-img-2"
          src="assets/img/normal/about_shape1-2.png"
          alt="img"
        />
      </div>
      <div className="container">
        <div className="row gx-60 align-items-center">
          <div className="col-xl-6">
            <div className="about-content-wrap">
              <div className="title-area me-xl-5 mb-20">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Hakkımızda
                </span>
               {/** <h2 className="sec-title">Lezzetli Yaşamlar, Yakın Mutfak’ta Başlar</h2>*/}

                <h2 className="sec-title">Lezzetli Yaşamlar, Yakın Mutfak’ta Başlar</h2>
               { about.length > 0 ? 
               <p className="sec-text mb-35">
                {about[0].About_ShortDesc}
                </p> : null}
              </div>

              
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">Kaliteli Hizmet</h3>
                    <p className="achive-about_text">
                   Yakın Mutfak
                    </p>
                  </div>
                </div>
              </div>
              <div className="achive-about">
                <div className="achive-about_content">
                  <div className="achive-about_icon">
                    <i className="fas fa-check" />
                  </div>
                  <div className="media-body">
                    <h3 className="box-title">
                      Hijyen
                    </h3>
                    <p className="achive-about_text">
                    Yakın Mutfak 

                    </p>
                  </div>
                </div>
              </div>
            
              <div className="btn-wrap mt-20">
                <Link to="/about" className="global-btn mt-xl-0 mt-20">
                 Daha Fazlası <i className="fas fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
