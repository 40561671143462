import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const TestimonialOne = () => {

  const [comment, setComment] = useState([]);
  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getOurServicePointsYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setComment(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); // Boş dizi sadece ilk renderde çalıştırır


  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="TestimonialOne-icon-box-next">
        <button
          onClick={onClick}
          className="slick-arrow style2 default team-slider2-next"
        >
          <i className="fas fa-arrow-right" />
        </button>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="TestimonialOne-icon-box-prev">
        <button
          onClick={onClick}
          className="team-slider2 slick-arrow style2 default team-slider2-prev"
        >
          <i className="fas fa-arrow-left" />
        </button>
      </div>
    );
  }
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {},
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="testimonial-area-1  overflow-hidden space-top">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="title-area">
              <span className="sub-title">
                <img src="assets/img/icon/title_left.svg" alt="shape" />
                HİZMET NOKTALARIMIZ
              </span>
              <h2 className="sec-title style2">
              İş Hedefleriniz Güvende
              </h2>
            </div>
            <div className="testimonial-thumb1">
              <img src="assets/img/testimonial/catering-buffet.jpg" alt="img" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="title-area">
              <p className="testi-desc">
                G4 Yakın Mutfak
              </p>
            </div>
            <div className="testi-area-slider">
              <div className="row global-carousel testi-slider-1">
                <Slider {...settings}>

                {comment.map((item, index) => (
                   <div className="p-2">
                   <div className="testi-card">
                     <div className="testi-card_content">
                      {/**  <div className="rating">
                         <i className="fas fa-star" />
                         <i className="fas fa-star" />
                         <i className="fas fa-star" />
                         <i className="fas fa-star" />
                         <i className="fas fa-star" />
                       </div>
                       <p className="testi-card_text">
                        {item.OurServicePoints_Title}
                       </p>*/}
                       <div className="testi-card-profile">
                         <div className="testi-profile_thumb">
                           <img
                             src={'https://megasubdomain.stechomeyazilim.info/' + item.OurServicePoints_Img}
                             alt="img"
                           />
                         </div>
                         <div className="testi-card-profile-details">
                           <h4 className="testi-profile-title">
                           {item.OurServicePoints_Title}
                           </h4>
                          {/**  <span className="testi-profile-desig">
                           {item.Comment_UserDesc}
                           </span>*/}
                         </div>
                       </div>
                     </div>
                    {/**  <div className="quote-icon">
                       <img src="assets/img/icon/quote1-1.svg" alt="img" />
                     </div>*/}
                   </div>
                 </div>
                ))}
                 

              
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialOne;
