import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ContactInner = (props) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [subject, setSubject] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("333322")
    try {
      const response = await axios.post('https://buharaapi.stechomeyazilim.info:5210/sendMailNew/insert', {
        name,
        phone,
        email,
        message,
      });

      console.log("response123",response)
      if (response.data = 'success') {
        setStatus('Mesajınız başarıyla gönderildi!');
        showToast(true)
      } else {
        setStatus('Mesaj gönderilirken bir hata oluştu.');
        showToast(false)
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Mesaj gönderilirken bir hata oluştu.');
      showToast(false)
    }
  };


  function showToast(type) {

    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla Mesajınız Gönderilmiştir!"
    } else{
       ele = "error"
       message = "Mesaj gönderilirken bir hata oluştu!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

    return (
         props.dataContact.length > 0 ? 
         <>
            <div className="map-sec">
                <iframe
                    src={props.dataContact[0].MapsLink}
                    allowFullScreen=""
                    loading="lazy"
                    title='Bizmaster'
                />
            </div>
         
            <div className="contact-area space">
                <div className="container">
                    <div className="contact-page-wrap bg-theme">
                        <div className="row gy-40 align-items-center">
                            <div className="col-lg-4 col-md-6">
                                <div className="contact-info">
                                    <div className="contact-info_icon">
                                        <i className="fas fa-phone-alt" />
                                    </div>
                                    <div className="contact-info_details">
                                        <h6 className="contact-info_title">Telefon</h6>
                                        <p className="contact-info_text">
                                            <span > {props.dataContact[0].Phone} </span> <br />
                                          
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="contact-info">
                                    <div className="contact-info_icon">
                                        <i className="fas fa-map-marker-alt" />
                                    </div>
                                    <div className="contact-info_details">
                                        <h6 className="contact-info_title">Konum</h6>
                                        <p className="contact-info_text">
                                        {props.dataContact[0].Adress} <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="contact-info">
                                    <div className="contact-info_icon">
                                        <i className="fas fa-envelope" />
                                    </div>
                                    <div className="contact-info_details">
                                        <h6 className="contact-info_title">Mail Adresi</h6>
                                        <p className="contact-info_text">
                                            <span> {props.dataContact[0].Mail}</span> <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="space-bottom">
                <div className="container">
                    <div className="row gy-40 justify-content-between">
                        <div className="col-lg-4">
                            <div className="title-area mb-0">
                                <span className="sub-title">
                                    <img src="assets/img/icon/title_left.svg" alt="shape" />
                                    İletişime Geç
                                </span>
                                <h2 className="sec-title style2">İletişime Geçin</h2>
                                <p className="mb-40">
                                    Bizi sosyal medyadan takip et {" "}
                                </p>
                                <div className="social-btn style4">
                                    <Link to="#" tabIndex={-1}>
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                    <Link to="#" tabIndex={-1}>
                                        <i className="fab fa-linkedin-in" />
                                    </Link>
                                    <Link to="#" tabIndex={-1}>
                                        <i className="fab fa-twitter" />
                                    </Link>
                                    <Link to="#" tabIndex={-1}>
                                        <i className="fab fa-instagram" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8">
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input
                                            type="text"
                                            placeholder="Adınız"
                                            className="form-control style-border"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input
                                            type="text"
                                            placeholder="Mail Adresiniz"
                                            className="form-control style-border"
                                            value={email}
                      onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input
                                            type="text"
                                            placeholder="Telefon Numaranız"
                                            className="form-control style-border"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <select
                                            name="subject"
                                            id="subject"
                                            className="form-select style-border"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        >
                                            <option defaultValue={1}>
                                                Konu
                                            </option>
                                            <option value={1}>Fiyat Teklifi</option>
                                            <option value={2}>Öneri</option>
                                            <option value={3}>Diğer ..</option>
                                        </select>
                                        <i className="fas fa-angle-down" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <textarea
                                            placeholder="Mesajınızı Yazınız.."
                                            className="form-control style-border"
                                            defaultValue={""}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12 form-group mb-0">
                                        <button className="global-btn w-100">
                                            Gönder
                                            <img src="assets/img/icon/right-icon.svg" alt="Bizmaster" />
                                        </button>

                                        {status && <p>{status}</p>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </> : null 

    )
}

export default ContactInner