import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Base64 } from 'js-base64';

const ProcessOne = (props) => {

  const [referances, setReferances] = useState([]);
  const [contact, setDataContact] = useState([]);



  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getAboutDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataContact(data);
      })
      .catch((error) => {
        console.log("API'den veri çekme hatası:", error);
      });


      const apiUrlReferances = "https://g4mutfakadmin.stechomeyazilim.info:9031/getReferancesYakin/select/";

    fetch(apiUrlReferances)
      .then((response) => response.json())
      .then((data) => {
        setReferances(data);
      })
      .catch((error) => {
        console.log("API'den veri çekme hatası:", error);
      });

  }, []); // Boş dizi sadece ilk renderde çalıştırır


  return (
    <>
      <section
        className="process-area-1 bg-smoke space-bottom"
        style={{ backgroundImage: "url(assets/img/bg/shape_1.png)" }}
      >

        <div className="client-bg-area-2 space-bottom">
          <div className="client-area-2">
            <div className=" global-carousel">
              <Marquee speed={30}>
             
             { props.getServices2.map((item, index) => {

              return( 
                <div>
                  <div className="client-logo">
                    <img className="client-logo" src={'https://megasubdomain.stechomeyazilim.info/' + item.Services2_Image} alt={item.Services2_Title} />
                  </div>
                  </div>
               )})}
             
               
              
               
             
              </Marquee>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="title-area text-center">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Güvenilir Hizmet
                </span>
                <h2 className="sec-title style2">
                 Yakın Mutfak
                </h2>
              </div>
            </div>
          </div>
          <div className="row gy-30">
            <div className="col-lg-6 col-xl-3 process-card-wrap">
              <div className="process-card">
                <div className="process-card_wrapp">
                  <div className="process-card-icon">
                    <img src="assets/img/icon/process-icon-1-1.svg" alt="img" />
                  </div>
                  <h3 className="process-card_number">01</h3>
                </div>
                <div className="process-card_content">
                  <span className="process-card-text">1.Adım</span>
                  <h4 className="process-card-title"> Deneme</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3 process-card-wrap">
              <div className="process-card">
                <div className="process-card_wrapp">
                  <div className="process-card-icon">
                    <img src="assets/img/icon/process-icon-1-2.svg" alt="img" />
                  </div>
                  <h3 className="process-card_number">02</h3>
                </div>
                <div className="process-card_content">
                  <span className="process-card-text">Teknik Operasyon</span>
                  <h4 className="process-card-title">Denemer</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3 process-card-wrap">
              <div className="process-card">
                <div className="process-card_wrapp">
                  <div className="process-card-icon">
                    <img src="assets/img/icon/process-icon-1-3.svg" alt="img" />
                  </div>
                  <h3 className="process-card_number">03</h3>
                </div>
                <div className="process-card_content">
                  <span className="process-card-text">Teknik Operasyon</span>
                  <h4 className="process-card-title"> Deneme</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-3 process-card-wrap">
              <div className="process-card">
                <div className="process-card_wrapp">
                  <div className="process-card-icon">
                    <img src="assets/img/icon/process-icon-1-4.svg" alt="img" />
                  </div>
                  <h3 className="process-card_number">04</h3>
                </div>
                <div className="process-card_content">
                  <span className="process-card-text">Teknik Operasyon</span>
                  <h4 className="process-card-title"> Deneme</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="goal-area space-top">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="title-area">
                <span className="sub-title">
                  <img src="assets/img/icon/title_left.svg" alt="shape" />
                  Yakın Mutfak Kimdir?
                </span>
                <h2 className="sec-title style2">
                Başarı için İş Ortaklığı
                </h2>
              </div>
            </div>
         {/**   <div className="col-lg-6">
              <div className="goal-title-area">
                <p className="">
                  Deneme ..
                </p>
              </div>
            </div>*/} 
          </div>
          <div className="row">
            <div className="goal-tabs-wrapper">
              <div
                className="nav nav-tabs goal-tabs-tabs"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link"
                  id="nav-step1-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-step1"
                  type="button"
                >
                  Vizyon
                </button>
                <button
                  className="nav-link"
                  id="nav-step2-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-step2"
                  type="button"
                >
                  Misyon
                </button>
                <button
                  className="nav-link active"
                  id="nav-step3-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-step3"
                  type="button"
                >
                  Deneyim
                </button>
                <button
                  className="nav-link"
                  id="nav-step4-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-step1"
                  type="button"
                >
                  Hakkımızda
                </button>

                <button
                  className="nav-link"
                  id="nav-step5-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-step5"
                  type="button"
                >
                  Yönetim Politikamız
                </button>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade" id="nav-step1" role="tabpanel">
                  <div className="goal-list_wrapper">
                    <div className="goal-content_wrapp">
                      <div className="goal-content">

                  {contact.length > 0 ?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(contact[0].Vision)) }} />
                                        : null}
                       
                       {/**  <h4 className="box-title">
                          Deneme ..
                        </h4>
                        <p>  Deneme ..</p>
                        <div className="checklist">
                          <ul>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                   
                  
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                  <div className="goal-list_wrapper">
                    <div className="goal-content_wrapp">

                      <div className="goal-content">

                           {contact.length > 0 ?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(contact[0].Mission)) }} />
                                        : null}
                                        {/** 
                        <h4 className="box-title">
                        Deneme ..
                        </h4>
                        <p>  Deneme ..</p>
                        <div className="checklist">
                          <ul>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                 
                  </div>
                </div>
                <div
                  className="tab-pane fade active show"
                  id="nav-step3"
                  role="tabpanel"
                >
                  <div className="goal-list_wrapper">
                    <div className="goal-content_wrapp">

                      <div className="goal-content">


                      {contact.length > 0 ?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(contact[0].Experience)) }} />
                                        : null}

                                        {/**
                        
                        <h4 className="box-title">
                        Deneme ..
                        </h4>
                        <p>  Deneme ..</p>
                        <div className="checklist">
                          <ul>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-step4" role="tabpanel">
                  <div className="goal-list_wrapper">
                    <div className="goal-content_wrapp">

                      <div className="goal-content">

                      {contact.length > 0 ?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(contact[0].Experience)) }} />
                                        : null}

                        {/** 
                        <h4 className="box-title">
                        Deneme ..
                        </h4>
                        <p>  Deneme ..</p>
                        <div className="checklist">
                          <ul>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                 
                  </div>
                </div>

                <div className="tab-pane fade" id="nav-step5" role="tabpanel">
                  <div className="goal-list_wrapper">
                    <div className="goal-content_wrapp">

                      <div className="goal-content">

                      {contact.length > 0 ?
                     <div dangerouslySetInnerHTML={{ __html: (Base64.decode(contact[0].OURMANAGEMENTPOLICY)) }} />
                                        : null}

                        {/** 
                        <h4 className="box-title">
                        Deneme ..
                        </h4>
                        <p>  Deneme ..</p>
                        <div className="checklist">
                          <ul>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                            <li>
                              <i className="fas fa-check" />
                              Deneme ..
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessOne;
