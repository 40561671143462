import React, { useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import HelmetReact from "../elements/HelmetReact";
import HeaderOne from "../components/HeaderOne";
import HeroOne from "../components/HeroOne";
import ServiceOne from "../components/ServiceOne";
import CTAOne from "../components/CTAOne";
import AboutOne from "../components/AboutOne";
import ProcessOne from "../components/ProcessOne";
import PortfolioOne from "../components/PortfolioOne";
import TestimonialOne from "../components/TestimonialOne";
import MarqueeOne from "../components/MarqueeOne";
import TeamOne from "../components/TeamOne";
import FaqOne from "../components/FaqOne";
import BlogOne from "../components/BlogOne";
import FooterOne from "../components/FooterOne";

const HomeOne = () => {
  let [active, setActive] = useState(true);
  
  const [dataService, setDataService] = useState([]);
  const [dataBlog, setDataBlog] = useState([]);
  const [dataContact, setDataContact] = useState([]);
  const [getServices2, setServices2] = useState([]);
  const [getAbout, setAbout] = useState([]);

  
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);

  
  useEffect(() => {
    const apiServices2 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServices2Yakin/select/";

    fetch(apiServices2)
      .then((response) => response.json())
      .then((data) => {
        setServices2(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getBlogDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataBlog(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 



  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getServicesYakin/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataService(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []); 


  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getContactDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setDataContact(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []);

  
  useEffect(() => {
    const apiUrl3 = "https://g4mutfakadmin.stechomeyazilim.info:9031/getAboutDnz/select/";

    fetch(apiUrl3)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data);
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }, []);
  

  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Helmet */}
      <HelmetReact title={"Anasayfa"} />

      {/* HeaderOne */}
      <HeaderOne getServices2={getServices2} dataContact={dataContact} dataService={dataService} dataBlog={dataBlog} />

      {/* HeroOne */}
      <HeroOne getServices2={getServices2}/>


      {/* ServiceOne */}
      <ServiceOne dataService={dataService}/>

      <MarqueeOne getServices2={getServices2}/>
      
      {/* CTA_One */}
      <CTAOne getAbout={getAbout}/>

      {/* AboutOne */}
      <AboutOne />

      {/* ProcessOne */}
      <ProcessOne getServices2={getServices2}/>

      {/* PortfolioOne   <PortfolioOne /> */}
   

      {/* TestimonialOne */}
      <div className="space-bottom"><TestimonialOne /></div>


      {/* MarqueeOne */}
     

      {/* <TeamOne /> TeamOne */}
     

      {/* FaqOne */}
      <div className="faq-area-1 bg-smoke space">
        <FaqOne />
      </div>

      {/* BlogOne */}
      <BlogOne />

      {/* FooterOne */}
      <FooterOne />
    </>
  );
};

export default HomeOne;
