import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServiceOne = (props) => {


  return (
    <div className="service-area-1 space overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <div className="title-area text-center">
              <span className="sub-title">
                <img src="assets/img/icon/title_left.svg" alt="shape" />
                Hizmetlerimiz
              </span>
              <h2 className="sec-title style2">
               G4 Yakın Mutfak
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="row gx-30 gy-30 justify-content-center">

      {props.dataService.map((item, index) => (
  
          <div className="col-md-6">
            <div className="service-card">
              <div className="service-card_img">
                <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Services_Image} alt="img" />
              </div>
              <div className="service-card_content">
                <h4 className="service-card_title">
                  <Link  to={ "/service-details/" + (item.ID)} state={{ _item: item}}>{item.Services_Title}</Link>
                </h4>
                <p className="service-card_text">
                {item.Services_ShortDesc}
                </p>
                <Link  to={ "/service-details/" + (item.ID)} state={{ _item: item}} className="link-btn">
                  Daha Fazlası <i className="fas fa-arrow-right" />
                </Link>
              </div>
            </div>
          
      </div>))}
      </div>
     
       
        </div>
    </div>
  );
};

export default ServiceOne;
