import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import IndexPage from "./pages/IndexPage";
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import HomeFour from "./pages/HomeFour";
import HomeFive from "./pages/HomeFive";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Project from "./pages/Project";
import ProjectDetails from "./pages/ProjectDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import { isMobile } from "react-device-detect";
import "react-whatsapp-chat-widget/index.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <RouteScrollToTop />

        <WhatsAppWidget
			phoneNo="+905325776301"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Merhaba?"
			iconSize= {isMobile == false ? "80" : "50" }
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://yakinmutfak.com/assets/img/logo.png"
			headerIconColor="#F41E1E"
			headerTxtColor="white"
			headerBgColor="#F41E1E"
			headerTitle="Yakın Mutfak"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Destek"
			chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesaj Türü.."
			btnBgColor="#0a4022"
			btnTxt="Konuşmaya Başla"
			btnTxtColor="white"
		/>

        <ScrollToTop smooth color="#5d4331" />
        <Routes>
          <Route exact path="/" element={<HomeOne />} />
          <Route exact path="/home-2" element={<HomeTwo />} />
          <Route exact path="/home-3" element={<HomeThree />} />
          <Route exact path="/home-4" element={<HomeFour />} />
          <Route exact path="/home-5" element={<HomeFive />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/service" element={<Service />} />
          <Route  path="/service-details/:id" element={<ServiceDetails />} />
          <Route exact path="/project" element={<Project />} />
          <Route  path="/project-details/:id" element={<ProjectDetails />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog-details/:id" element={<BlogDetails />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/team" element={<Team />} />
          <Route  path="/team-details/:id" element={<TeamDetails />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
